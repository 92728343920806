<template>
<div id="contentwrap">
  <PageTitle />
  <article class="row content-section">
    <div class="inner">
      <header>
        <h1 class="title">STAFF</h1>
      </header>
      <hr>
      <div>
        <img src="/images/team-photo.jpg" alt="Meredith's Team">
      </div>
      <hr>
      <div class="col span3of3 textleft teaminfo">
        <div class="teammember">
          <div class="profile">
            <div class="profile_right">
              <img class="team_avatar" src="/images/team-img-amanda.jpg" width="150px" height="150px" alt="Amanda Fox - Meredith's Team">
            </div>
            <div class="profile_left">
              <h2 class="name">Amanda Fox</h2>
              <h5 class="position">Chief Operating Officer&nbsp;<br> <span>818.430.8485</span> <br> <span>afox.sventures@gmail.com</span><br>DRE# 02046889</h5>
            </div>
          </div>
          <p>
            <span>
            As Chief Operating Officer, Amanda runs our office with the utmost care, professionalism and efficiency. Bringing over six years of experience, she ensures a smooth transaction for our buyers and sellers from start to finish and makes the process as stress free as possible!
            <br><br>
            In addition to handling all administrative duties, Amanda does everything from property evaluations, identifying homes for buyers, marketing and so much more. Her attention to detail, tireless work ethic and open style of communication allows her to provide the ultimate client experience.
            <br><br>
            Born and raised in Los Angeles, Amanda has extensive knowledge of LA's distinct neighborhoods giving her a unique perspective.
            <br><br>
            Amanda is a graduate from University of California, Santa Cruz with Dean's Honors. Growing up with a mother as an interior designer, Amanda has an eye for design. Her affinity for real estate developed at a young age after spending countless weekends looking at open houses with her mother.
            <br><br>
            When she is not working, Amanda enjoys cooking, reading, playing tennis and hiking with her two English bulldogs.
            </span>
          </p>
        </div>
        <hr>
        <div class="teammember">
          <div class="profile">
            <div class="profile_right">
              <img class="team_avatar" src="/images/team-img-morgan.jpg" width="150px" height="150px" alt="Morgan Stefanacci - Meredith's Team">
            </div>
            <div class="profile_left">
              <h2 class="name">Morgan Stefanacci</h2>
              <h5 class="position">Digital Marketing Manager <br> <span>marketing.sventures@gmail.com</span></h5>
            </div>
          </div>
          <p>
            Morgan Stefanacci brings over four years of luxury real estate marketing experience to The Meredith Schlosser Team. Contributing to approximately $2 billion in sales volume, Morgan has a deep understanding of Los Angeles’s various neighborhoods and price points. Through thoughtful digital and print campaigns, Morgan ensures properties receive widespread exposure to assist with a quick sale.
          </p>
          <p>
            Originally from the East Coast, Morgan first made her way West at The University of Colorado Boulder graduating with a B.S. in Journalism and B.A. in Communications.
          </p>
          <p>
            Prior to entering the world of real estate, she worked within the public relations departments for notable companies including Rollingstone and Kidrobot. Outside of work, Morgan can be found at Soulcycle, traveling, or spending time at home in West Hollywood with her boyfriend & two Aussiedoodles.
          </p>
        </div>
        <hr>
        <div class="teammember">
          <div class="profile">
            <div class="profile_right">
              <img class="team_avatar" src="/images/team-img-megan.jpg" width="150px" height="150px" alt="Megan Linden Alcott - Meredith's Team">
            </div>
            <div class="profile_left">
              <h2 class="name">Megan Linden Alcott</h2>
              <h5 class="position">Transaction Coordinator <br> <span>818.970.8527</span> <br> <span>meganlalcott@gmail.com</span><br>DRE #01946746 </h5>
            </div>
          </div>
          <p>
            Megan Alcott is a seasoned professional with 5-plus years of experience as a Transaction Coordinator with Berkshire Hathaway HomeServices.  Megan is known for building trusting relationships with her clients and delivering best-in-class services pertinent to creating, building, and processing a successful real estate file.  Megan serves as the gatekeeper for information gathered between both buyer, seller and agents, distributes and analyzes key documents related to the transaction, and keeps a mindful eye on the workflow to ensure all deadlines are met. Her goal is simple; to create a collaborative, stress-free, and pleasurable experience for her clients!
          </p>
          <p> </p>
          Megan was born and raised in Sherman Oaks, CA and comes from a family with a deep-rooted passion for real estate.  In her spare time, Megan enjoys reading, exercising, and spending time with her husband and two young children.
          <p></p>
        </div>
      </div>
      <header class="section">
        <h1 class="title">AGENTS</h1>
      </header>
      <hr>
      <div class="col span3of3 textleft teaminfo">
        <div class="teammember">
          <div class="profile">
            <div class="profile_right">
              <img class="team_avatar" src="/images/team-img-david.jpg" width="150px" height="150px" alt="Monnie Fannin - Meredith's Team">
            </div>
            <div class="profile_left">
              <h2 class="name">David Bober</h2>
              <h5 class="position">Real Estate Agent<br> <span>917.595.0776</span> <br> <span>dbober.sventures@gmail.com</span> <br>DRE# 02151058</h5>
            </div>
          </div>
          <p>
            David joins the Meredith Schlosser Team after a long and successful career in marketing, business development, and finance. He comes to the team with over 30 years of experience, having negotiated $500 million in executed contracts. David started his career by forming a talent representation firm that represented over 40 clients including Mia Hamm, one of the world’s most recognizable female athletes. Eight years later, he sold his agency to Octagon, a global sports/entertainment agency. As an EVP with Octagon and later his own firm, David raised more than $85 million for the New York City Department of Education’s sports and physical education programs through public/private partnerships. David also participated in venture capital/private equity opportunities highlighted by his tenure on the Board of tech company Stylesight that had a successful exit, and was an early investor and Board member of an automated sports production company that was later acquired and became part of Sportradar, now a publicly traded company.
            <br><br>
            David and his wife Jeni have always had a passion for real estate and developing residential properties in and around the Westside of Los Angeles. For many people, buying or selling a home is one of the important financial decisions of their lives. This fuels the couple’s ambition to over deliver on the services they are proud to provide clients. David and Jeni strive to make the process as seamless and smooth as possible; from negotiating the best possible price to managing all of the paperwork and logistics, no detail is too small to ensure we meet and exceed expectations.
          </p>
        </div>
        <hr>
        <div class="teammember">
          <div class="profile">
            <div class="profile_right">
              <img class="team_avatar" src="/images/team-img-jeni.jpg" width="150px" height="150px" alt="Monnie Fannin - Meredith's Team">
            </div>
            <div class="profile_left">
              <h2 class="name">Jeni Bober</h2>
              <h5 class="position">Real Estate Agent<br> <span>310.801.2733</span> <br> <span>jeni.sventures@gmail.com</span> <br>DRE# 02040636</h5>
            </div>
          </div>
          <p>
            After she purchased her first home in Marina del Rey, Jeni knew she wanted a career in real estate. She takes the responsibility of helping clients make one of the most important financial decisions in their lives very seriously.She is passionate and knowledgeable about the industry, which fuels her ambition to be an invaluable part of the home buying and selling process. Jeni prides herself on delivering excellent customer service and is truly invested in helping her clients meet their home buying needs. You’ll find her to be warm, approachable, detail-oriented, collaborative and very enthusiastic about her career and personal life.
            <br><br>
            Originally from Canada, Jeni studied Political Science at the University of Toronto. After she met her husband, they decided to relocate to New York. Upon relocation, she had a successful stint in luxury fashion sales and merchandising before deciding to take a break to start a family. As an East Coast transplant now living on the West Coast, she loves everything Los Angeles has to offer.
            <br><br>
            Jeni’s husband David is also an agent with the Meredith Schlosser Team and the couple work together on behalf of their shared clients in partnership with the team. They strive to make the home buying and selling process as seamless as possible in order to exceed all client expectations. When she’s not working, you can find her relaxing with her husband and son, walking her dog, on a yoga mat, crafting or watching sci-fi movies. Contact Jeni if you or anyone you know is looking to buy real estate on the Westside.
          </p>
        </div>
        <hr>
        <div class="teammember">
          <div class="profile">
            <div class="profile_right">
              <img class="team_avatar" src="/images/team-img-jeremy.jpg" width="150px" height="150px" alt="Meredith's Team">
            </div>
            <div class="profile_left">
              <h2 class="name">Jeremy Hensley</h2>
              <h5 class="position">Real Estate Agent <br> <span>310.346.9807</span> <br> <span>jeremy.sventures@gmail.com</span><br>DRE# 02078139</h5>
            </div>
          </div>
          <p>
            Jeremy jumped into the world of real estate after a successful career in the fashion industry. Always looking for a new challenge - Jeremy’s love for sales, interior design and architecture led him to this exciting new adventure. <span class="readmore">
            &nbsp;<br><br>
            Born and raised on a farm in Indiana, Jeremy's humble upbringing sets him apart from other agents. His down to earth attitude and calm approach allows him to connect with his clients organically. Jeremy’s attention to detail and strong work ethic, gives his clients peace of mind in knowing their real estate transaction process will be as seamless and efficient as possible.
            &nbsp;<br><br>
            When he isn't showing clients their potential new home, Jeremy can be found enjoying the surf and sand on a beach in SoCal or traveling home to his family’s farm for a relaxing stroll through the countryside on his horse.
            </span>
          </p>
        </div>
        <hr>
        <div class="teammember">
          <div class="profile">
            <div class="profile_right">
              <img class="team_avatar" src="/images/team-img-monnie.jpg" width="150px" height="150px" alt="Monnie Fannin - Meredith's Team">
            </div>
            <div class="profile_left">
              <h2 class="name">Monnie Fanning</h2>
              <h5 class="position">Real Estate Agent<br> <span>310.344.3736</span> <br> <span>monnie.sventures@gmail.com</span> <br>DRE# 01947570</h5>
            </div>
          </div>
          <p>
            Monnie entered into Real Estate after many years in the Los Angeles Tech Industry.  Monnie’s passion for real estate combined with her desire to help people are what drive her to find her clients their perfect home. Monnie strives to make her clients feel at ease while she walks them through each step of the home buying process.
            <br><br>
            A resident of California for over 20 years, Monnie understands the wide variety of her client’s needs as well as the diverse aspects of the many Los Angeles neighborhoods. Her professionalism, specific neighborhood knowledge and positive attitude benefit her clients in a competitive Los Angeles real estate market.
            <br><br>
            Monnie holds a BS in Business Administration and a BA in Spanish from Pepperdine University.
            <br><br>
            In her free time, Monnie enjoys cooking, hiking, walking on the beach and spending time with her husband and three little dogs.
          </p>
        </div>
      </div>
    </div>
  </article>
</div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "Team",
    components: {
        PageTitle,
    },
    data(){
        return{
        }
    },
    metaInfo: {
        title: 'Team',
        titleTemplate: '%s | Meredith Schlosser',
        meta: [{
            name: 'description',
            content: "Meredith Schlosser Team - Real Estate Agent: Outgoing, Tireless, and a Commitment to Excellence. She's the Director of Aaroe International Luxury Properties and a member of the SFJ Group."
        },
        {
            name: 'author',
            content: "Meredith Schlosser"
        },]
    },
}
</script>

<style lang="scss" scoped>
.section{
  margin-top: 60px;
}
</style>